import React from "react";
import { Seo } from "../../components";
import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import { DepartmentInfo } from "../../components/department-info";

const DepartmentBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.department_info.name}
        description={serverData.department_info.markdown}
        image_url={serverData.department_info.image_url}
      />
      <DepartmentInfo
        path_prefix="departments"
        selected_department={serverData.department_info.slug}
        department_info={serverData.department_info}
        departments_index={serverData.departments_index}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const department_info_res = await fetch(
      BACKEND_BASE_URL +
        "departments/department_type-department/" +
        context.params.slug
    );
    const departments_index_res = await fetch(
      BACKEND_BASE_URL + "departments/department_type-department"
    );
    if (!department_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!departments_index_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        department_info: await department_info_res.json(),
        departments_index: await departments_index_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default DepartmentBySlug;
