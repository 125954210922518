import React from "react";
import { Dropdown, DepartmentBSection, CouncilList, NewsEventSlider } from "./";
import { navigate } from "gatsby";

const DepartmentInfo = ({
  selected_department,
  department_info,
  departments_index,
  path_prefix,
}) => {
  console.log(selected_department);
  const onChange = e => {
    navigate("/" + path_prefix + "/" + e.target.value);
  };
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={departments_index}
              selected={selected_department}
              onChange={onChange}
            />
            <>
              <DepartmentBSection data={department_info} />
              <CouncilList councils={department_info?.sub_departments} />
              <NewsEventSlider />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DepartmentInfo };
